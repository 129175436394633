import Vue from "vue"
import Vuex from "vuex"
import Cookies from "js-cookie"


Vue.use(Vuex)

const store = new Vuex.Store({
    state:{
     token: Cookies.get("token"),
     userInfo: Cookies.get("userInfo")
    },
    mutations: {
     setToken (state, token) {
       state.token = token
       Cookies.set("token", token, { expires: 27 })
     },
     setUserInfo (state, userInfo) {
      state.userInfo = userInfo
      Cookies.set("userInfo", userInfo, { expires: 27 })
    },
    removeToken(state, token){
      state.token = token;
    }
   },
   actions: {
     setToken ({commit}, token) {
       return new Promise((resolve, reject) => {
         commit("setToken", token)
         resolve()
       })
     },
     setUserInfo ({commit}, userInfo) {
      return new Promise((resolve, reject) => {
        commit("setUserInfo", userInfo)
        resolve()
      })
    }
   },
   getters: {
     token: state => state.token,
     userInfo: state => state.userInfo,
     userName:state => JSON.parse(state.userInfo).userName
   },
   modules: {
   }
   })

export default store
