import axios from 'axios';
//import qs from 'qs';

const ajax = axios.create({
    timeout: 50000,
    withCredentials: true,
   baseURL: "https://www.yaoguai-api-online.cn/",
    //baseURL: "http://localhost:20000/",
    //baseURL: "http://39.98.161.171:20000/",
    //baseURL: "https://www.quanzi123.cn/",
    headers: { 'X-Requested-With': 'XMLHttpRequest', 'tuluban_platform': 'web' },
    // transformRequest: [
    //   (data,config) => {
    //     console.log('+++++++++',data,config);
    //     return data;
    //   }
    // ]
})

let cloneGet = ajax.get
ajax.get = (url, data) => cloneGet(url, { params: data });

//let clonePost = ajax.post
//ajax.post = ajax.post

ajax.post = (url, data) => ajax({

    url: url,
    method: 'post',
    data: data
});

ajax.interceptors.request.use(function(config) {
    if (config.method === 'get') {
        return config;
    }
    // if(config.data instanceof FormData){
    //   return config;
    // }
    config.headers['Content-Type'] = 'application/json'

    //  if(config.requestType !== 'json'){
    // config.data = qs.stringify(config.data, { allowDots: true });
    //  }
    return config;
}, function(error) {
    return Promise.reject(error);
});

ajax.interceptors.response.use(
    (response) => {
        // eslint-disabled-next-line
        // 做事情
        // msg && message.error(msg);
        return response.data
    },
    (error) => {
        // 这里可以做事情
        return Promise.reject(error)
    }
)


export default ajax