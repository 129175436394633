<template>
  <div>
    <div class="layui-fluid banner">
      <div class="layui-container">
        <div class="infor">
          <h1>
            电子证件照制作，换<span style="color: #409eff">背景颜色</span>
          </h1>
          <p style="color: #51637c">
            快捷： 智能制作，生活照也可以变证件照<br />方便：
            价格便宜，线上操作，送货上门
          </p>
          <el-upload
            ref="upload1"
            action=""
            class="upload-demo"
            :before-upload="beforeUpload"
            :http-request="uploadFile"
            accept=".png,.jpg"
            :multiple="false"
            :limit="10"
            :show-file-list="false"
            :auto-upload="false"
            :on-change="changeFile"
          >
            <label class="uploadbtn tran"><span>上传照片</span></label>
          </el-upload>
          <p class="font2" style="color: #51637c; opacity: 0.7">
            按照以下要求将帮助您获取标准证件照：
          </p>

          <ul>
            <li>
              <p
                style="
                  background-image: url(https://www.yaoguai-oss-pub.cn/image/image_cache/2021/06/01/e6191570-2d86-4a0b-acf9-f42607a2987b);
                "
              >
                不要上传翻拍的照片
              </p>
            </li>
            <li>
              <p
                style="
                  background-image: url(https://www.yaoguai-oss-pub.cn/image/image_cache/2021/06/01/f754b0bd-e7c3-4909-98c2-33010d5b2af2);
                "
              >
                光线充足、露出耳朵眉毛
              </p>
            </li>
            <li>
              <p
                style="
                  background-image: url(https://www.yaoguai-oss-pub.cn/image/image_cache/2021/06/01/8a37a7ac-f18d-4cf4-a883-45a73f5a6cf7);
                "
              >
                正面坐正，抬头挺胸
              </p>
            </li>
            <li>
              <p
                style="
                  background-image: url(https://www.yaoguai-oss-pub.cn/image/image_cache/2021/06/01/40551cd5-3e59-46fa-861e-6aa92ed57a77);
                "
              >
                不要佩戴首饰与帽子
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- 图片操作 -->
    <el-container class="photo">
      <el-main class="elMain">
        <el-upload
          ref="upload"
          class="upload-demo"
          drag
          action=""
          :before-upload="beforeUpload"
          :http-request="uploadFile"
          accept=".png,.jpg"
          :multiple="false"
          :limit="10"
          :show-file-list="false"
          :auto-upload="false"
          :on-change="changeFile"
          :style="{
            width: '100%',
            height: '100%',
            'padding-left': '20px',
          }"
        >
          <div class="iconClass" v-if="photoId == 0">
            <i class="el-icon-camera" style="margin: auto"></i>
            <div class="el-upload__text fontClass">
              上传图片开始制作<em>点击上传</em>
            </div>
          </div>

          <div
            v-if="photoId != 0"
            class="sure-wrap"
            style="width: 295px; height: 413px; margin: auto"
          >
            <div class="horn">
              <div class="lt"></div>
              <div class="rt"></div>
              <div class="rb"></div>
              <div class="lb"></div>
            </div>
            <!-- <div data-v-64ea1706="" class="wpx">295px</div>
                                <div data-v-64ea1706="" class="hpx">413px</div>
                                <div data-v-64ea1706="" class="wmm">25mm</div>
                                <div data-v-64ea1706="" class="hmm">35mm</div> -->
            <!-- <img 
                                     src=""
                                     ondragstart="return false;" width="" height="413" alt="" class="upload-img"
                                     style=""> -->

            <el-image
              style="height: 413px"
              :src="resultImage"
              fit="contain"
            ></el-image>
            <div style="padding-top: 10px">
              <div class="photoname">
                {{ showName }}<span class="unitClass">（{{ showUnit }}）</span>
              </div>
            </div>
          </div>

          <!-- <img v-if="imageUrl" src="https://www.yaoguai-oss-pub.cn/image/image_cache/2021/05/23/b9e0807d807047b8d732d71df5abc01a" class="avatar"> -->
          <!-- <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
          <!-- <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div> -->
        </el-upload>
      </el-main>
      <el-aside style="overflow: visible; padding-right: 20px">
        <el-card shadow="always" class="box-card">
          <div slot="header" class="clearfix">
            <span class="card-title">
              <i class="el-icon-download"></i>保存</span
            >
            <el-upload
              ref="upload2"
              action=""
              class="upload-demo"
              :before-upload="beforeUpload"
              :http-request="uploadFile"
              accept=".png,.jpg"
              :multiple="false"
              :limit="10"
              :show-file-list="false"
              :auto-upload="false"
              :on-change="changeFile"
            >
              <el-button
                v-if="photoId != 0"
                style="float: right; padding: 3px 0"
                type="text"
                >重新上传</el-button
              ></el-upload
            >
          </div>
          <div style="height: 20px" class="text item">
            <el-button size="small" type="primary" @click="savePhoto()"
              >保存图片<i class="el-icon-check el-icon--right"></i
            ></el-button>

            <el-button size="small" type="primary" @click="getCodeURl()"
              >排版打印<i class="el-icon-printer el-icon--right"></i
            ></el-button>
          </div>
        </el-card>

        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span class="card-title">
              <i class="el-icon-setting"></i>背景色</span
            >
            <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
          </div>
          <div style="margin-top: 5px">
            <el-radio-group
              @change="changeColor"
              :fill="fillColor"
              v-model="radio2"
              size="small"
              :text-color="textColor"
            >
              <el-radio-button
                style="margin-left: 10px"
                label="蓝色"
              ></el-radio-button>
              <el-radio-button
                style="margin-left: 10px"
                label="白色"
              ></el-radio-button>
              <el-radio-button
                style="margin-left: 10px"
                label="红色"
              ></el-radio-button>
            </el-radio-group>
          </div>
        </el-card>

        <el-card class="box-card" body-style="padding:0px;">
          <div slot="header" class="clearfix">
            <span class="card-title"> <i class="el-icon-user"></i>换装</span>
            <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
          </div>
          <div>
            <el-tabs
              stretch
              style="width: 100%"
              v-model="activeName"
              type="card"
              @tab-click="handleClick"
            >
              <el-tab-pane label="男装" name="boy"></el-tab-pane>
              <el-tab-pane label="女装" name="girl"></el-tab-pane>
              <el-tab-pane label="儿童" name="kid"></el-tab-pane>
            </el-tabs>

            <!-- <div style="height:200px;overflow: scroll"> -->
            <happy-scroll
              color="rgba(0,0,0,0.5)"
              size="5"
              style="height: 200px; width: 100%"
            >
              <el-row
                style="padding-bottom: 10px"
                v-for="i in items"
                :key="i.name"
                :gutter="20"
              >
                <el-col :span="7" v-for="im in i" :key="im.name">
                  <!-- <div> -->
                  <div @click="choose(im.name)" style="position: relative">
                    <img class="curImage" :src="im.url" fit="cover" />
                    <div v-if="im.name == clothesType" class="mask">
                      <img
                        class="phimage"
                        src="https://www.yaoguai-oss-pub.cn/image/image_cache/2021/05/20/e1b7bb7c-ac81-4ef7-be57-6c02685879ba"
                        fit="cover"
                      />
                    </div>
                  </div>
                </el-col>
              </el-row>
            </happy-scroll>
          </div>
        </el-card>
      </el-aside>
    </el-container>

    <div class="maincontent">
      <h1 class="fonttitle">常见问题</h1>
      <!-- <div> -->
      <div class="question">
        <div>
          <div class="ask">
            <span class="qtit">问</span>
            <h1>支付成功后没有下载证件照？</h1>
          </div>
          <div class="answer">
            <span class="qtit">答</span>
            <p>
              由于网络异常或者系统延迟问题，如果支付成功后没有下载证件照，你可以联系人工处理，&nbsp;&nbsp;
              <a
                target="_blank"
                href="http://wpa.qq.com/msgrd?v=3&uin=858299224&site=qq&menu=yes"
                ><img
                  border="0"
                  src="http://wpa.qq.com/pa?p=2:858299224:51"
                  alt="图鲁班-证件照制作"
                  title="图鲁班-证件照制作" /></a
              ><br />
            </p>
          </div>
        </div>
      </div>

      <div class="question">
        <div>
          <div class="ask">
            <span class="qtit">问</span>
            <h1>在使用过程中遇到问题？</h1>
          </div>
          <div class="answer">
            <span class="qtit">答</span>
            <p>
              提升系统稳定性，优化用户体验一直使我们的追求，遇到任何问题，您可以通过人工客服反馈给我们，我们及时改进。<br />
            </p>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="选择证件照规格"
      :visible.sync="dialogFormVisible"
      width="70%"
    >
      <el-autocomplete
        popper-class="my-autocomplete"
        v-model="state"
        :fetch-suggestions="querySearch"
        placeholder="搜索你要制作的规格"
        @select="handleSelect"
        :trigger-on-focus="false"
        style="width: 80%; padding-bottom: 20px"
      >
        <i class="el-icon-search el-input__icon" slot="suffix"> </i>
         <template slot-scope="{ item }">
          <div class="name">{{ item.formatName }} <span class="unitClass">({{ item.photoWidth }}x{{ item.photoHeight }} {{ item.unit }})</span></div>
          <span ></span>
        </template>
      </el-autocomplete>

      <happy-scroll
        color="rgba(0,0,0,0.5)"
        size="5"
        style="height: 400px; width: 800px"
      >
        <el-row
          style="padding-bottom: 10px; width: 800px"
          v-for="i in certificateType"
          :key="i.id"
          :gutter="20"
        >
          <el-col
            :xs="12"
            :sm="12"
            :lg="{ span: '4-8' }"
            class="card-panel-col"
            v-for="im in i"
            :key="im.name"
          >
            <!-- <div> -->
            <!-- <div @click="choose(im.formatId)" style="position: relative">
              
              
            </div> -->

            <el-card
              shadow="always"
              :body-style="{ padding: '0px' }"
              style="width: 130px"
            >
              <div
                @click="
                  choseFormat(
                    im.formatName,
                    im.formatId,
                    im.photoWidth,
                    im.photoHeight,
                    im.unit
                  )
                "
              >
                <div style="position: relative">
                  <img class="curImage1" :src="im.iconUrl" fit="cover" />
                  <div v-if="im.formatName == formatName" class="mask1">
                    <img
                      class="phimage"
                      src="https://www.yaoguai-oss-pub.cn/image/image_cache/2021/05/20/e1b7bb7c-ac81-4ef7-be57-6c02685879ba"
                      fit="cover"
                    />
                  </div>
                </div>
                <div style="padding: 0px">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="im.formatName"
                    placement="top"
                  >
                    <div class="photoname">{{ im.formatName }}</div>
                  </el-tooltip>
                </div>
                <div>
                  <span class="unitClass"
                    >{{ im.photoWidth }}x{{ im.photoHeight }}{{ im.unit }}</span
                  >
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </happy-scroll>

      <div slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogFormVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="submitPhoto()">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="祛除水印-保存图片" :visible.sync="dialogTableVisiblePay">
      <el-row>
        <el-col :span="12"
          ><el-image
            style="width: 200px; height: 200px"
            :src="payCodeUrl"
            fit="contain"
          ></el-image>
          <div>
            <img
              style="width: 40px; heigth: 40px"
              src="https://www.yaoguai-oss-pub.cn/image/image_cache/2021/05/29/a017956a-a185-4d3b-836f-0f847d5305a6
"
            />
            <span style="font-size: 20px">微信扫一扫</span>
          </div>
        </el-col>
        <el-col :span="12">
          <div style="float: left; font-size: 20px; padding-top: 60px">
            应付金额:<span class="money">￥{{ price }}</span>
          </div>
          <div
            style="float: left; clear: both; font-size: 15px; padding-top: 5px"
          >
            支付成功后，自动下载
          </div>
        </el-col>
      </el-row>
    </el-dialog>
    <el-dialog title="提交订单" :visible.sync="dialogTableVisiblePrint">
      <el-row>
        <el-col :span="24"
          ><el-image
            style="width: 200px; height: 200px"
            :src="codeUrl"
            fit="contain"
          ></el-image>
          <div>
            <img
              style="width: 40px; heigth: 40px"
              src="https://www.yaoguai-oss-pub.cn/image/image_cache/2021/05/29/a017956a-a185-4d3b-836f-0f847d5305a6
"
            />
            <span style="font-size: 20px">微信扫一扫-填写订单地址</span>
          </div>
        </el-col>
       
      </el-row>
    </el-dialog>

    <div>
      <el-link target="_blank" href="https://beian.miit.gov.cn" type="info"
        >京ICP备 2020038026号</el-link
      >
    </div>
  </div>
</template>


<!-- 引入css，该链接始终为最新版的资源 -->

<!-- 引入vue -->
<!-- 引入组件，该链接始终为最新版的资源 -->


<script>
import ajax from "../common/js/axios.js";
//import ajaxOSS from "../common/js/axiososs.js";
import SparkMD5 from "spark-md5";
import axios from "axios";
import { Loading } from "element-ui";
//import affix from 'easy-affix'
import "../common/css/question.css";

export default {
  metaInfo: {
    title: "证件照-证件照制作-标准证件照制作-图鲁班",
    meta: [
      {
        name: "keywords",
        content:
          "图鲁班,证件照制作,ai电子证件照制作,证件照换底色,一寸照片，2寸照片，各个国家签证",
      },
      {
        name: "description",
        content:
          "图鲁班,电子证件照在线制作工具,全球证件照合规性检测&证件照制作平台，包含300多种规格尺寸，一键完成证件照合规性检测及制作。支持一寸，二寸，四六级考试，教师资格证，驾驶证，毕业证等照片，包含美国、泰国、日本、新加坡、马来西亚60多个国家的签证。",
      },
    ],
  },
  data() {
    return {
      imageUrl: "true",
      dialogFormVisible: false,
      dialogTableVisiblePay: false,
      dialogTableVisiblePrint:false,
      fillColor: "#3F91E4",
      textColor: "#ffffff",
      radio2: "蓝色",
      activeName: "first",

      centerDialogVisible: false,
      payCodeUrl: "",
      listResult: [],
      items: [],
      clothes: {},
      restaurants: [],
      state: "",
      certificateType: [],
      clothesType: "",
      formatId: 1,
      colorCode: 2,
      photoId: 0,
      md5: "",
      loadingInstance: "",
      resultImage: "",
      formatName: "一寸",
      price: 2.9,
      showName: "一寸",
      showUnit: "",
      codeUrl:""
    };
  },
  created: function () {
    console.log("dddd");
    ajax.post("certificate/getClothesTypeWeb", {}).then((response) => {
      console.log(",ddd");
      console.log(response);
      this.items = response.meta.boy;
      this.clothes = response.meta;
      console.log(this.items);
    });
    ajax.post("certificate/searchCertificateWeb", {}).then((response) => {
      console.log(response);

      this.certificateType = response.meta.result;
      console.log(this.certificateType);
    });
  },
  methods: {
    querySearch(queryString, cb) {
      if(queryString == ''){
        return;
      }
        ajax.post("certificate/searchCertificateV2", {
          keyword:queryString
        }).then((response) => {
         console.log(response);
       
         cb(response.meta.result);
      //this.certificateType = response.meta.result;
      //console.log(this.certificateType);
    });
      // var restaurants = this.restaurants;
      // var results = restaurants;
      // debugger;
     
      // cb(results);
    },
    savePhoto() {
      if (this.photoId == 0) {
        this.$message("请上传源证件照哦");
        return;
      }
      this.getPaySign();
    },
    getCodeURl(){
      let form = {};
       let ordertype = 5;
      if (this.clothesType != "" && this.clothesType.indexOf("none") == -1) {
        ordertype = 15;
       
      }
      let scene = this.formatId+","+this.photoId+","+this.colorCode+","+ordertype;
      form.scene = scene;
       ajax.post("certificate/getPayCode", form).then((response) => {
         this.codeUrl = response.meta.codeUrl;
         this.dialogTableVisiblePrint = true;
        //this.payCodeUrl = response.meta.url;

        //this.dialogTableVisiblePay = true;
        //this.checkPayStatus(27);
        // this.checkPayStatus(response.meta.orderId);
      });

    },
    getPaySign() {
      let form = {};
      form.photoId = this.photoId;
      form.formatId = this.formatId;
      let ordertype = 0;
      if (this.clothesType != "" && this.clothesType.indexOf("none") == -1) {
        
        ordertype = 10;
        this.price = 9.9;
      }else{
        this.ordertype=0;
        this.price = 2.9;
      }
      form.orderType = ordertype;
      ajax.post("order/getPaySinWeb", form).then((response) => {
        this.payCodeUrl = response.meta.url;

        this.dialogTableVisiblePay = true;
        //this.checkPayStatus(27);
         this.checkPayStatus(response.meta.orderId);
      });
    },
    checkPayStatus(orderId) {
      let _this = this;
      let intervalID = setInterval(function () {
        console.log("轮训");

        ajax
          .post("order/checkPayStatus", {
            orderId: orderId,
          })
          .then((response) => {
            console.log("res..." + JSON.stringify(response));
            if (response.code == 200) {
              let str = response.meta.url;

              _this.downloadIamge(str, "证件照");

              clearInterval(intervalID);
            }
          })
          .catch((error) => {
          
            clearInterval(intervalID);
          });
      }, 2000);
    },
    downloadIamge(imgsrc, name) {
      //下载图片地址和图片名

      var image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL("image/jpg"); //得到图片的base64编码数据

        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = name || "photo"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = imgsrc;
    },
    submitPhoto() {
      
      this.dialogFormVisible = false;
      this.$refs.upload.submit();
      this.$refs.upload1.submit();
      this.$refs.upload2.submit();
    },
    choseFormat(formatName, formatId, width, height, unit) {
      this.formatName = formatName;
      this.formatId = formatId;
      this.showName = formatName;
      this.showUnit = width + "x" + height + unit;
    },
    handleSelect(im) {
      
      this.choseFormat(im.formatName,im.formatId,im.photoWidth,im.photoHeight,im.unit)
      //console.log(item);
      this.submitPhoto();
    },
    changeColor(event) {
      console.log(event);
       if (this.photoId == 0) {
        this.$message("请先上传源证件照哦");
        return;
      }
      if (event == "红色") {
        this.fillColor = "#ff0000";
        this.textColor = "#ffffff";
        this.colorCode = 1;
      } else if (event == "蓝色") {
        this.fillColor = "#3F91E4";
        this.textColor = "#ffffff";
        this.colorCode = 2;
      } else {
        this.fillColor = "#fff2f9";
        this.textColor = "#0d0000";
        this.colorCode = 0;
      }
      this.getCertificatePhoto();
    },
    choose(e) {
      this.clothesType = e;
      console.log("ordertype..." + e);
      this.getCertificatePhoto();
    },
    load() {},
    handleClick(tab, event) {
      console.log(tab, event);
      if (tab.name == "boy") {
        this.items = this.clothes.boy;
      } else if (tab.name == "girl") {
        this.items = this.clothes.girl;
      } else if (tab.name == "kid") {
        this.items = this.clothes.kid;
      }
    },
    toPhoto() {
      document.querySelector(".photo").scrollIntoView(true);
    },
    change() {},
    changeFile(file, fileList) {
      console.log(file);
      console.log(fileList);
      this.dialogFormVisible = true;
    },
    uploadFile(file) {
      //console.log("开始上传")
      console.log(file);
      //let md5 = this.handlePrepareUpload(file);
      //console.log(md5);
    },
    hadStar() {
      this.centerDialogVisible = false;
      localStorage.hasStar = 1;
    },
    preWord: function (wordUrl) {
      let url = "https://view.officeapps.live.com/op/view.aspx?src=" + wordUrl;

      window.open(url, "_blank");
    },
    downLoadWord: function (wordUrl) {
      console.log("...00 " + localStorage.hasStar == 0);
      console.log("..." + localStorage.hasStar);
      window.open(wordUrl);
      if (localStorage.hasStar != 1) {
        this.centerDialogVisible = true;
      }
    },
    beforeUpload: function (file) {
      const size = file.size / 1024 / 1024;
      if (size > 10) {
        this.$notify.info({
          title: "提示",
          message: "文件不能超过10m",
        });
        return;
      }
      let options = {};
      options.text = "证件照制作中...请你耐心等待";
      this.loadingInstance = Loading.service(options);
      this.handlePrepareUpload(file).then((res) => {
        this.uploadFiles(res, file)
          .then((res) => {
            //loadingInstance.close();
            console.log(res);
            //this.listResult.push(res);
            this.getCertificatePhoto();
          })
          .catch((err) => {
            console.log(err);
            this.loadingInstance.close();
            this.$notify({
              title: "消息",
              message: "转换错误，请刷新页面重试",
              type: "warning",
            });
          });
      });
    },
    getCertificatePhoto: function () {
      let _this = this;
      console.log("md5..." + _this.md5);
      if (_this.md5 == "" || _this.md5 == undefined) {
        return;
      }
      let options = {};
      options.text = "证件照制作中...请你耐心等待";
      this.loadingInstance = Loading.service(options);
      console.log("type..." + _this.clothesType);
      ajax
        .post("certificate/makePhoto", {
          md5: _this.md5,
          colorCode: _this.colorCode,
          formatId: _this.formatId,
          clothesType: _this.clothesType,
        })
        .then((res) => {
          this.loadingInstance.close();

          if (res.code != 200) {
            this.$notify.info({
              title: "提示",
              message: "检测图片不和规范，在换张图片试试哦",
            });
          } else {
            document.querySelector(".photo").scrollIntoView(true);
            //this.resultImage=res.
            this.photoId = res.meta.photoId;
            this.resultImage = res.meta.photoUrl;
            console.log("image..." + this.resultImage);
            console.log(this.photoId);
            this.$notify({
              title: "成功",
              message: "证件照制作完成",
              type: "success",
            });
            // _this.showAddImage = false;
            // _this.imageUrl = res.data.meta.photoUrl;
            // _this.photoId = res.data.meta.photoId;
          }
        });
    },
    uploadFiles: function (md5, file) {
      this.md5 = md5;
      return new Promise(function (resolve, reject) {
        ajax.get("preImage?md5=" + md5, {}).then((response) => {
          console.log(response);
          if (response.code == 201) {
            resolve(response.meta);
            return response.meta;
          }
          if (response.code == -45) {
            reject(response);
            return response;
          }
          let responData = response.meta.bean;
          //  var form = {};
          //
          // form.policy = responData.policy;
          // form.OSSAccessKeyId = responData.accessid;
          // form.signature = responData.signature;
          // form.success_action_status = "200";
          // form.key = responData.ossKey;
          // form.fileKey = responData.fileKey;
          // form.callback = responData.base64CallbackBody;

          let formData1 = new window.FormData();

          formData1.append("key", responData.ossKey);
          formData1.append("policy", responData.policy);
          formData1.append("OSSAccessKeyId", responData.accessid);
          formData1.append("success_action_status", "200");
          formData1.append("callback", responData.base64CallbackBody);
          formData1.append("signature", responData.signature);
          formData1.append("file", file);
          axios({
            url: "https://" + responData.host,
            method: "post",
            data: formData1,
            headers: { "Content-Type": "multipart/form-data" },
          }).then((res) => {
            console.log("res.." + JSON.stringify(res));
            if (res.data.Status == "OK") {
              let intervalID = setInterval(function () {
                console.log("轮训");
                ajax
                  .post("getImageStatus", {
                    md5: md5,
                  })
                  .then((response) => {
                    console.log("res..." + JSON.stringify(response));
                    if (response.code == 200) {
                      clearInterval(intervalID);
                      resolve(response.meta);
                      return response.meta;
                    } else if (response.code == -45) {
                      clearInterval(intervalID);
                      reject(response);
                      return response;
                    } else if (response.code == 203) {
                      //clearInterval(intervalID)
                      //reject(response);
                      //return response;
                      console.log("kjk");
                      /* uni.showToast({
																	title: '检测到上传文件共'+response.meta.pages+"页",请您耐心等待,
																	icon: 'none'
																}); */
                      return;
                    } else if (response.code == 205) {
                      return;
                    } else {
                      clearInterval(intervalID);
                      reject(response);
                      return response;
                    }
                  })
                  .catch((error) => {
                    clearInterval(intervalID);
                    reject(error);
                    return error;
                  });
              }, 2000);
            } else {
              console.log("oss出错。。。");
              reject(res);
              return res;
            }
          });
        });
      });
    },

    handlePrepareUpload: function (file) {
      return new Promise(function (resolve) {
        const fileSize = file.size; // 文件大小
        const chunkSize = 1024 * 1024 * 10; // 切片的大小
        const chunks = Math.ceil(fileSize / chunkSize); // 获取切片个数
        const fileReader = new FileReader();
        const spark = new SparkMD5.ArrayBuffer();
        const bolbSlice =
          File.prototype.slice ||
          File.prototype.mozSlice ||
          File.prototype.webkitSlice;
        let currentChunk = 0;

        fileReader.onload = (e) => {
          const res = e.target.result;
          spark.append(res);
          currentChunk++;
          if (currentChunk < chunks) {
            loadNext();
            console.log(
              `第${currentChunk}分片解析完成, 开始第${currentChunk + 1}分片解析`
            );
          } else {
            const md5 = spark.end();

            //alert(md5)
            resolve(md5);
            return md5;
          }
        };

        const loadNext = () => {
          const start = currentChunk * chunkSize;
          const end =
            start + chunkSize > file.size ? file.size : start + chunkSize;
          fileReader.readAsArrayBuffer(bolbSlice.call(file, start, end));
        };
        loadNext();
      });
    },
  },
};
</script>



<style scoped>
body {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: "Microsoft YaHei", "微软雅黑", Arial;
  color: #747474;
}
body {
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
  color: #7048cb;
}
input,
textarea:focus {
  outline: none;
}
img {
  border: 0;
}
ul,
li {
  list-style-type: none;
}
.clear {
  clear: both;
}
strong {
  font-size: inherit;
  color: inherit;
}
area {
  outline: none;
}
a,
span {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
}

.fl {
  float: left;
}
.fr {
  float: right;
}
.sm-clear,
.xs-clear {
  height: 0 !important;
  width: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
}
.layui-fluid {
  padding: 0;
}
.bgffffff {
  background: #ffffff;
}
.bgf3f4fe {
  background: #f3f4fe;
}
.all-main {
  padding: 0 0 50px;
  min-height: 500px;
}
a.cfff:hover {
  color: #ffffff;
}
/*footbg*/
.custom-shape-divider-top-1596528856 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-top-1596528856 svg {
  position: relative;
  display: block;
  width: calc(103% + 1.3px);
  height: 68px;
}

.custom-shape-divider-top-1596528856 .shape-fill {
  fill: #f3f4fe;
}

/*casebg*/
.custom-shape-divider-top-1596529177 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-top-1596529177 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 33px;
  transform: rotateY(180deg);
}

.custom-shape-divider-top-1596529177 .shape-fill {
  fill: #ffffff;
}

/*biaogebg*/
.custom-shape-divider-bottom-1596532164 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-bottom-1596532164 svg {
  position: relative;
  display: block;
  width: calc(115% + 1.3px);
  height: 101px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1596532164 .shape-fill {
  fill: #ffffff;
}

/*内页banner开始*/

/*内页banner结束*/
.tran {
  transition: all 0.5s 0.2s;
  -moz-transition: all 0.5s 0.2s;
  -webkit-transition: all 0.5s 0.2s;
  -o-transition: all 0.5s 0.2s;
}

.top {
  background: #ffffff;
  box-shadow: 0px 3px 10px 4px rgba(231, 233, 254, 0.6);
}
.top .logo {
  width: 177px;
  float: left;
  padding: 22px 0 24px;
}
.top .menu {
  float: right;
  padding: 18px 0 0;
}
.top .menu a {
  border: 1px solid #bcaee7;
  border-radius: 3px;
  colorp: #4821ed;
  letter-spacing: 1px;
  padding: 10px 23px;
  margin-left: 16px;
  display: inline-block;
}
.top .menu a:hover {
  background-color: #7048cb;
  color: #ffffff;
  border: 1px solid #7048cb;
}

.banner {
  background-repeat: no-repeat;
  background-position: center bottom;
  padding-bottom: 30px;
}

.banner .infor p {
  font-size: 16px;
  line-height: 200%;
}
.banner .infor h1 {
  font-size: 44px;
  font-weight: 700;
  color: #000000;
  padding-top: 35px;
  padding-bottom: 10px;
}
.banner .infor ul {
  width: 50%;
  padding-top: 5px;
  margin-bottom: 30px;
  margin: auto;
}
.banner .infor li {
  width: 215px;
  display: inline-block;
}
.banner .infor li p {
  background-repeat: no-repeat;
  background-size: 32px;
  background-position: left center;
  padding: 7px 0 7px 41px;
  color: #000000;
  font-size: 15px;
}
.banner .infor p.font2 {
  font-size: 15px;
}
.uploadbtn {
  width: 302px;
  height: 73px;
  background: linear-gradient(
    0deg,
    #409eff 0%,
    #ffffff 0%,
    #409eff 0%,
    #409eff 100%
  );
  border-radius: 36px;
  box-shadow: 0px 5px 15px 0px rgba(86, 93, 255, 0.5);
  display: inline-block;
  text-align: center;
  margin: 32px 0 35px;
}
.uploadbtn span {
  font-size: 28px;
  font-weight: 700;
  color: #ffffff;
  letter-spacing: 2px;
  color: #ffffff;
  display: inline-block;
  line-height: 73px;
  /* background-image: url(../images/btnpic.png); */
  background-repeat: no-repeat;
  background-position: left 12px;
  background-size: 88px;
}
.uploadbtn:hover {
  color: #ffffff;
  width: 340px;
  box-shadow: 0px 8px 15px 0px rgba(86, 93, 255, 0.6);
}
.uploadbtn:hover span {
  letter-spacing: 5px;
}

.biaoge {
  background: #e7e9fe;
}
.nomaltitle {
  color: #ffffff;
  font-size: 22px;
  color: #67649d;
  font-weight: 700;

  background-size: 43px;
  background-repeat: no-repeat;
  background-position: left center;
  padding: 10px 0 10px 50px;
  margin: 0 0 20px;
}
.setting {
  background-image: linear-gradient(to right, #4b3fc5, #6134c5);
  border-radius: 20px;
  padding: 40px 3%;
  text-align: center;
  max-width: 1500px;
  margin: 0 auto;
}
.loadpic {
  height: 453px;
  background: #ffffff;
  border-radius: 20px;
}
#myuploadpic,
#myuploadpiclist,
.loadpic .nopic {
  /*max-width: 418px;*/
  width: auto;
  max-height: 70%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.setting p {
  color: #c8c0e9;
  position: relative;
}
.setting p.beizhu {
  line-height: 170%;
  margin-bottom: 30px;
}
.setting p.beizhu a {
  color: #ffffff;
}
.setting p.beizhu a:hover {
  text-decoration: underline;
}
.setting p.beizhu:before {
  content: "*";
  color: #fff100;
  position: absolute;
  left: -15px;
  top: 3px;
  font-size: 20px;
}
.setting h1 {
  color: #ffffff;
  font-size: 18px;
  padding-bottom: 12px;
}
.setting .nomaltitle {
  color: #ffffff;
  text-align: left;
}






.case {
  background: #f3f4fe;
}
.case .content {
  padding: 80px 0;
}
.case .pic {
  text-align: center;
}
.casebody {
  padding: 0 35px;
}
.case .pic img {
  width: 90%;
}

.foot {
  background: #7048cb;
}
.foot .content {
  text-align: center;
  padding: 30px 0;
}
.foot .content p {
  font-weight: 400;
  color: #ffffff;
}
.foot .content p.bei {
  font-size: 12px;
  opacity: 0.5;
  line-height: 180%;
  padding-top: 20px;
}
.foot .content img {
  max-width: 179px;
  width: 40%;
  padding: 30px 0;
}

.nbanner {
  background-image: linear-gradient(to right, #4b3fc5, #6134c5);
}
.nbanner img {
  padding-left: 15%;
  max-width: 721px;
  width: 60%;
}
.mainbody {
  margin-top: -130px;
  position: relative;
  padding-bottom: 60px;
}
.maincontent {
  border-radius: 20px;
  background-color: #ffffff;
  padding: 80px 8%;
}
.guide {
  color: #ffffff;
  display: inline-block;
  font-size: 16px;

  background-repeat: no-repeat;
  background-position: left center;
  background-size: 16px;
  position: absolute;
  right: 15px;
  top: -45px;
  padding-left: 25px;
}
.guide i {
  font-style: normal;
  color: #ffffff;
  padding: 0 10px;
  font-size: 16px;
}
.guide a:hover {
  color: #ffffff;
  text-decoration: underline;
}





@media screen and (min-width: 1200px) {
}

@media screen and (max-width: 1199px) {
}

@media screen and (max-width: 992px) {
  .step .item .pic img {
    width: 50%;
    max-width: 90px;
  }
  .step .item .pic h1 {
    font-size: 14px;
    padding-top: 8px;
  }
  .step .item .pic {
    top: 18%;
  }
  .loadpic {
    min-height: 516px;
  }
  .ordersearch {
    padding: 22px 30px;
    margin: 0 0 70px;
  }
  .ordersearch .okbtn {
    top: 32px;
  }
  .ordersearch .tit {
    font-size: 14px;
    padding-bottom: 10px;
    width: 100%;
  }
}

.layui-col-space80 > * {
  padding: 0px 2.8%;
}
.layui-col-space80 {
  margin: 0 -2.8%;
}
.layui-col-space160 > * {
  padding: 0px 3.3%;
}
.layui-col-space160 {
  margin: 0 -3.3%;
}
.layui-form-select dl dd.layui-this {
  background-color: #7048cb;
}
.layui-form-select .layui-input {
  color: #000000;
}
.layui-input,
.layui-select,
.layui-textarea {
  height: 42px;
}

@media screen and (min-width: 769px) {
  .parameter .szpart {
    max-width: 313px;
  }
}

.photo {
  height: 98vh;
}

/deep/.el-upload-dragger {
  width: 100% !important;
  height: 97vh;
}
/deep/.el-upload {
  height: 100% !important;
  display: block;
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.card-title {
  float: left;
}

.box-card {
  width: 100%;
}
.clearfix {
  height: 10px;
}

.elMain {
  padding: 0px 20px 0px 20px;
  height: 98vh;
}
.curImage {
  overflow: hidden;
  height: 70px;
  width: 70px;
  z-index: 1;
}
.curImage1 {
  overflow: hidden;
  height: 70px;
  width: 70px;
  z-index: 1;
  margin-top: 10px;
}

.mask {
  position: absolute;
  width: 70px;
  height: 70px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 4px;
  text-align: center;
  z-index: 10;
  top: 0;
  left: 0;
}
.mask1 {
  position: absolute;
  width: 130px;
  height: 130px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 4px;
  text-align: center;
  z-index: 10;
  top: 0;
  left: 0;
}
.phimage {
  margin-top: 30px;
  width: 30px;
  height: 30px;
}

.el-col-lg-4-8 {
  width: 20%;
}
.photoname {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80%;
  word-break: normal;
  margin: auto;
  font-size: 14px;
  color: black;
}
.customWidth {
  width: 80%;
}
.unitClass {
  font-size: 5px;
  color: #cacbce;
}

.sure-wrap {
  width: 100%;
  height: 100%;
  position: relative;
  top: 50px;
}

.sure-wrap .horn {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 13px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  top: -13px;
  left: -13px;
  border: 1px solid #e5e5ea;
}

.sure-wrap .horn > div {
  width: 24px;
  height: 24px;
  position: absolute;
}

.sure-wrap .horn .lt {
  border-top: 3px solid #00d3e7;
  border-left: 3px solid #00d3e7;
  left: -2px;
  top: -2px;
}

.sure-wrap .horn .rt {
  border-top: 3px solid #00d3e7;
  border-right: 3px solid #00d3e7;
  right: -2px;
  top: -2px;
}

.sure-wrap .horn .rb {
  border-right: 3px solid #00d3e7;
  right: -2px;
}

.sure-wrap .horn .lb,
.sure-wrap .horn .rb {
  border-bottom: 3px solid #00d3e7;
  bottom: -2px;
}

.sure-wrap .horn .lb {
  border-left: 3px solid #00d3e7;
  left: -2px;
}
.iconClass {
  font-size: 90px;
  padding-top: 10%;
}
.fontClass {
  font-size: 30px;
}
/deep/.el-upload__text {
  line-height: 0px !important;
  font-size: 20px;
}

.money {
  font-size: 28px;
  color: #ff2500;
  font-weight: 600;
  margin-left: 10px;
}
</style>
