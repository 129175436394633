//import { Descriptions } from 'ant-design-vue'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
import certificate from "../views/certificate.vue"


const routes = [
  { path: '/', redirect: '/certificate' },
  {
    path: '/pdf',
    name: 'pdf',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/pdf.vue'),
   
  }, {
    path: '/certificate',
    name: 'certificate',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: certificate,

  }, {
    path: '/certificate1',
    name: 'certificate1',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/certificate1.vue'),

  },
  {
    path: '/pdf-to-image',
    name: 'pdfToImage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/pdf/pdfToImage'),

  },
  {
    path: '/pdf-to-excel',
    name: 'pdfToExcel',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/pdf/pdfToExcel'),

  },
  {
    path: '/pdf-to-txt',
    name: 'pdfToTxt',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/pdf/pdfToTxt'),

  }, {
    path: '/anime',
    name: 'anime',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/anime.vue'),

  }, {
    path: '/watermark',
    name: 'watermark',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/watermark.vue'),

  },{
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/login/login.vue'),

  },{
    path: '/zip',
    name: 'zip',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/util/zip.vue'),

  },{
    path: '/background',
    name: 'background',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/util/background.vue'),

  },{
    path: '/color',
    name: 'color',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/util/color.vue'),

  },{
    path: '/px',
    name: 'px',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/util/px.vue'),

  },{
    path: '/jpg',
    name: 'jpg',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/util/format.vue'),

  }
  ,{
    path: '/dpi',
    name: 'dpi',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/util/dpi.vue'),

  },{
    path: '/print',
    name: 'print',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/util/print.vue'),

  }

]



const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
