import Vue from 'vue'
import App from './App.vue'
import store from "./vuex"

import router from './router'
import ElementUI from 'element-ui'
//import { Affix } from 'view-design';
import wxlogin from 'vue-wxlogin';
import Cookies from "js-cookie"

Vue.component('wxlogin',wxlogin);

import VueCropper from 'vue-cropper' 

Vue.use(VueCropper)



import { Affix } from 'ant-design-vue';

import container from './components/container.vue'
Vue.component('Container', container)


import { HappyScroll } from 'vue-happy-scroll'
//自定义组件名
Vue.component('happy-scroll', HappyScroll)
// 引入css
import 'vue-happy-scroll/docs/happy-scroll.css'


import 'element-ui/lib/theme-chalk/index.css'
//import 'view-design/dist/styles/iview.css';
import 'ant-design-vue/dist/antd.css'

import MetaInfo from 'vue-meta-info';
Vue.use(MetaInfo);



Vue.config.productionTip = false;
Vue.use(ElementUI)
Vue.use(Affix);

//Vue.component('Affix', Affix);
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  console.log( Cookies.get('token'));

  if (to.path.indexOf("/diy") !=0 && (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
   
    console.log("ddd");
    //next('https://www.apdf.top')
    window.location.href='https://www.apdf.top'
  } else {
    const token = Cookies.get('token');
  if(token != null && token !=  undefined){
    console.log("登录");
    next()
  }else if(to.path.indexOf("/certificate1") >-1) {
    console.log("not login");
    next('/login');
    //未登录
  }else{
    next();
  }
  }
  
 

});
new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
    console.log("...启动。。")
    //let userInfo = JSON.parse(localStorage.getItem('token'));
    //window.sessionStorge.setItem('unserInfo',{});
    //Cookies.remove("token");
       // Cookies.remove("userInfo");
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
