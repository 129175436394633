<template>
  <div>
    <el-menu
      :default-active="$route.path"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
      router
    >
      <el-menu-item>
        <a href="/certificate">
          <div>
            <!-- <el-image class="logoImage" src="https://www.yaoguai-oss-pub.cn/image/image_cache/2021/05/12/04118163-e817-48ed-9d72-3156ad39fbd6"></el-image> -->
            <div class="logoFont">图鲁班</div>
            <div class="logoFont">tuluban.top</div>
          </div>
        </a>
      </el-menu-item>
      <el-menu-item index="/certificate">证件照制作</el-menu-item>
      <el-menu-item index="/background">证件照换底色</el-menu-item>
      <!-- <el-submenu index="">
        <template slot="title">PDF处理</template>
        <el-menu-item index="/pdf">pdf转word</el-menu-item>
        <el-menu-item index="/pdf-to-image">pdf转图片（image）</el-menu-item>
        
        <el-menu-item index="/pdf-to-txt">pdf转文本（txt）</el-menu-item>
      </el-submenu> -->

      <!-- <el-menu-item style="padding-left:20px;" >PDF处理</el-menu-item> -->
      <el-menu-item index="/zip">图片压缩</el-menu-item>
      <el-menu-item index="/jpg">照片格式转换</el-menu-item>
      <el-menu-item index="/color">图片颜色提取</el-menu-item>
      <el-menu-item index="/px">图片改像素</el-menu-item>
     
      <el-submenu >
        <template slot="title">更多工具</template>
        <el-menu-item index="/dpi">图片改分辨率</el-menu-item>
      <el-menu-item index="/print">照片排版</el-menu-item>
        </el-submenu>
      <el-menu-item v-if="user.userId == 1164871 || user.userId==1583818" index="/diy">订单中心</el-menu-item>
      <div class="login" v-if="this.$store.getters.token == ''  || this.$store.getters.token == undefined">
        <el-button type="text"><el-link href="/login" :underline="false" type="primary">登录</el-link></el-button>
      </div>
      <!-- <div class="login"  ><el-button type="text">{{user.userName}}</el-button></div> -->

      <el-dropdown
        @command="handleCommand"
        placement="top"
        class="login"
        v-if="this.$store.getters.token != '' && this.$store.getters.token != undefined"
      >
        <div class="login">
          {{this.$store.getters.userName }}<i class="el-icon-arrow-down el-icon--right"></i>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="1">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-menu>
  </div>
</template>
<script>
import Cookies from "js-cookie";

export default {
  data() {
    return {
      user:this.$store.getters.userInfo==undefined?{}:JSON.parse(this.$store.getters.userInfo),
      
    };
  },
  created(){
   
  this.user=this.$store.getters.userInfo==undefined?{}:JSON.parse(this.$store.getters.userInfo);
  },

  activated(){
 this.user=this.$store.getters.userInfo==undefined?{}:JSON.parse(this.$store.getters.userInfo);
  
  },
  computed: {},
  watch: {},
  props: {},
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    handleCommand(e) {
      if (e == 1) {
        this.$store.commit('removeToken', '');
        Cookies.remove("token");
        Cookies.remove("userInfo");
        location.reload();
      }
    },
    logout() {
     
    },
  },
  mounted() {
     
    // this.user = localStorage.getItem("userInfo");
    //console.log("userinfo"+this.user)
    
  },
};
</script>
<style scoped>
.powerdata-header {
  background-color: #f2f2f2;
  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  width: 100%;
}
.logoImage {
  margin-left: 10px;
  float: left;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
.logoFont {
  font-size: 20px;
  float: left;
  margin-left: 5px;
  color: #44d8e1;
  margin-bottom: 0px;
}
.login {
  float: right;
  padding-right: 30px;
  height: 60px;
  display: flex;
  justify-content: space-around;
  cursor: pointer;
  align-items: center;
}
</style>
 